import React from "react"

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"

import './general.sass'
import {graphql} from "gatsby";

const GeneralPage = ({data}) => {
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { html, frontmatter } = markdownRemark

    return (
        <Layout>
            <SEO title={ frontmatter.title }/>
            <div className={'generalPage container section bottom'}>

                <h1 className={'title'}>{frontmatter.title}</h1>
                <h4 className={'subtitle'}>{ frontmatter.subtitle }
                </h4>

                <div className="text-container left">
                    <div className="generalPage--body" dangerouslySetInnerHTML={{__html: html}} />
                </div>
            </div>
        </Layout>
    )
};

export default GeneralPage

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(frontmatter: { path: { eq: $id } }) {
      html
      frontmatter {
        path
        title
        subtitle
      }
    }
  }
`;
